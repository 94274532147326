<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-24 10:32:32
 * @LastEditTime: 2023-07-06 10:52
 * @Descripttion: 课程表设置
-->
<style lang="scss" scoped>
.lesson-set {
    @include innerPage(40px 0 48px);
    @include pageTitle(32px);

    .page-title .operaty-buttons {
        margin-right: auto;
        margin-left: 46px;
    }

    .school-timetable {
        width: calc(100% - 48px);
        height: calc(100% - 38px);
        box-sizing: border-box;
        margin-left: 20px;
        padding-top: 30px;
        position: relative;

        &--inner {
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;
            margin: 0 auto;

            &.edit {
                height: calc(100% - 72px);
                padding-right: 114px;
            }

            &.not_edit {
                height: 100%;
                transition-delay: 0.2s;
            }

            .inner-data,
            .inner-head,
            .timetable {
                width: 100%;
            }

            .inner-data {
                border: 1px solid #e6e8f1;
                border-collapse: collapse;
                border-spacing: 0;
                table-layout: fixed;
            }

            .inner-head {
                width: 100%;
                height: 74px;
                font-size: 16px;
                background: #eff1fa;

                &--col {
                    width: 12.5%;
                    border: 1px solid #e6e8f1;
                    vertical-align: middle;
                    text-align: center;

                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            .timetable {
                &-row {
                    width: 100%;
                    height: 136px;
                    position: relative;

                    .row-del {
                        position: absolute;
                        right: -65px;
                        top: 49px;
                    }

                    &:last-child {
                        .timetable-col:first-child {
                            border-bottom-left-radius: 10px;
                        }

                        .timetable-col:nth-child(8) {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }

                &-col {
                    width: 12.5%;
                    box-sizing: border-box;
                    padding: 0 20px;
                    color: #1a1a1a;
                    border: 1px solid #e6e8f1;
                    background: #f5f7fe;
                    text-align: center;
                    vertical-align: middle;
                    position: relative;

                    &.time {
                        font-family: 'PingFang SC Medium';
                        font-size: 16px;
                        line-height: 24px;
                    }

                    p {
                        white-space: nowrap;
                        line-height: 24px;

                        .bold {
                            font-size: 16px;
                        }

                        .light {
                            margin-left: 9px;
                            font-size: 14px;
                        }
                    }

                    .col-btns {
                        width: 100%;
                        position: absolute;
                        bottom: 14px;
                        left: 0;
                        opacity: 0;
                        @include flexBox(space-between);

                        .el-button {
                            padding: 0 20px;
                        }

                        .success {
                            margin-left: 0;
                        }
                    }

                    &:hover .col-btns {
                        opacity: 1;
                    }
                }
            }
        }

        .edit-tool {
            height: 72px;
            box-sizing: border-box;
            padding-top: 34px;
            margin-left: -4px;
            padding-right: 114px;

            &.show {
                transform: scaleY(1);
                transition-delay: 0.2s;
                opacity: 1;
            }

            &.hide {
                transform: scaleY(0);
                opacity: 0;
            }

            .tool-operaty {
                float: right;
            }
        }
    }

    .import-dialog {
        box-sizing: border-box;
        padding: 40px 20px 28px;

        .download-formwork {
            text-align: right;
            margin-bottom: 18px;
        }

        .file-list {

            .file-item {
                height: 60px;
                margin-top: 20px;
                @include flexBox(space-between, flex-end);

                .iconfont-color {
                    font-size: 52px;
                    flex-shrink: 0;
                    line-height: 60px;
                    flex-shrink: 0;
                }

                .el-button {
                    flex-shrink: 0;
                    margin-bottom: 6px;
                }

                .name {
                    color: #212122;
                    flex-grow: 1;
                    margin: 0 20px 10px;
                }
            }
        }
    }

    .time-dialog {
        box-sizing: border-box;
        padding: 40px 20px 28px;
    }

    .lesson-dialog {
        box-sizing: border-box;
        padding: 40px 20px 28px 34px;
    }
}
</style>
<style lang="scss">
.print-table {
    position: fixed;
    top: 200vh;
    left: 0;

    &--data {
        background: #fff;

        th,
        td {
            width: 10%;
            box-sizing: border-box;
            padding: 0 10px;
        }

        th {
            height: 50px;
        }

        td {
            width: calc(100% / 8);
            height: 80px;
            vertical-align: middle;
            text-align: center;

            &:first-child {
                font-size: 16px;
            }

            .bold {
                font-size: 16px;
                font-weight: bolder;
            }

            .light {
                font-size: 14px;
                font-weight: lighter;
                margin-left: 8px;
            }
        }
    }
}
</style>
<template>
    <section class="lesson-set">
        <div class="page-title">
            <h3>课程表</h3>
            <div class="operaty-buttons">
                <el-button type="custom_success" size="small" @click="openImport">导入</el-button>
                <template v-if="!isEdit">
                    <el-button type="custom_warning" size="small" @click="printRow">打印</el-button>
                    <el-button type="custom_primary" size="small" @click="isEdit = true">编辑</el-button>
                </template>
            </div>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <!-- 课程表 -->
        <div class="school-timetable">
            <!-- 课程表表格 -->
            <div class="school-timetable--inner" ref="timetable" :class="{ edit: isEdit, not_edit: !isEdit }">
                <table class="inner-data">
                    <thead>
                        <tr class="inner-head pf_bold">
                            <th class="inner-head--col">时间</th>
                            <th class="inner-head--col">一</th>
                            <th class="inner-head--col">二</th>
                            <th class="inner-head--col">三</th>
                            <th class="inner-head--col">四</th>
                            <th class="inner-head--col">五</th>
                            <th class="inner-head--col">六</th>
                            <th class="inner-head--col">日</th>
                        </tr>
                    </thead>
                    <tbody class="timetable">
                        <tr class="timetable-row" v-for="(item, index) in tableData" :key="index">
                            <td class="timetable-col time">
                                <p>{{ item.time_text }}</p>
                            </td>
                            <td class="timetable-col" v-for="(citem, cindex) in item.classedule" :key="cindex">
                                <template v-if="citem.data.sysub_id">
                                    <p class="line-text--1st">
                                        <span class="bold">{{ citem.data.sysub_name }}</span>
                                        <span class="light">{{ citem.data.teuse_name }}</span>
                                    </p>
                                    <div class="col-btns" v-if="isEdit">
                                        <el-button type="text" class="danger"
                                            @click="delLessonCol(citem.data.sccla_sch_id)">
                                            清除
                                        </el-button>
                                        <el-button type="text" class="success" @click="openLessonDialog(citem.data)">
                                            <span class="iconfont">&#xe61e;</span>
                                            <span>修改</span>
                                        </el-button>
                                    </div>
                                </template>
                                <template v-else>
                                    <el-button v-if="isEdit" type='primary' size="mini" plain
                                        @click="openLessonDialog(item.sccla_sch_tim_id, cindex + 1)">
                                        填充
                                    </el-button>
                                </template>
                            </td>
                            <el-button class="row-del iconfont normal" type="custom_primary" circle v-if="isEdit"
                                @click="delLessonRow(item.sccla_sch_tim_id)">
                                &#xe620;
                            </el-button>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 编辑操作功能按钮 -->
            <div class="edit-tool" :class="{ show: isEdit, hide: !isEdit }">
                <div class="tool-operaty">
                    <el-button type="custom_info" size="small" @click="isEdit = false">取消</el-button>
                </div>
            </div>
        </div>
        <!-- 打印数据内容 -->
        <div class="print-table">
            <table class="print-table--data" id='printData' border="1" cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th>时间</th>
                        <th>一</th>
                        <th>二</th>
                        <th>三</th>
                        <th>四</th>
                        <th>五</th>
                        <th>六</th>
                        <th>日</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ item.time_text }}</td>
                        <td v-for="(citem, cindex) in item.classedule" :key="cindex">
                            <p>
                                <span class="bold">{{ citem.data.sysub_name }}</span>
                                <br>
                                <span class="light">
                                    {{ citem.data.teuse_name && citem.data.teuse_name.substr(0, 15) || '' }}
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 操作弹窗 -->
        <el-dialog title="导入" :visible.sync="importDialog" width="560px" :show-close="false" @close="hideDialog">
            <div class="import-dialog">
                <p class="download-formwork">
                    <el-button type="custom_primary" size="small" @click="downloadTemplate">下载模板</el-button>
                </p>
                <el-upload :action="$upload.fileAction2" :headers="$upload.header" drag 
                    :show-file-list="fileList.length==0" 
                    :file-list="fileList" 
                    accept=".xls,.xlsx,.xlsm"
                    :before-upload="(file) => { return $beforFileUpload(file, ['xls', 'xlsx', 'xlsm']) }"
                    :on-success="uploadSuccess">
                    <p class="iconfont">&#xe603;</p>
                    <p class="tips">拖拽或<span>点击上传</span></p>
                </el-upload>
                <ul class="file-list">
                    <li class="file-item" v-for="(item, index) in fileList" :key="index">
                        <span class="iconfont-color" v-html="fileType(item.name.split('.')[1])" />
                        <p class="name line-text--1st">{{ item.name }}</p>
                        <el-button class="iconfont mini" type="custom_primary" circle @click="deleteUploadFile(index)">
                            &#xe620;
                        </el-button>
                    </li>
                </ul>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="uploadTimetable" :disabled="btnload"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 数据填充 -->
        <el-dialog :title="lessonForm.sccla_sch_id && '修改' || '填充'" :visible.sync="lessonDialog" width="560px"
            :show-close="false" @close="hideDialog('lessonForm')">
            <div class="lesson-dialog">
                <el-form ref="lessonForm" :model="lessonForm" :rules="rules" label-width="68px">
                    <el-form-item label="科目" prop="sysub_id">
                        <el-select v-model="lessonForm.sysub_id" placeholder="请选择" filterable clearable
                            @change="getTeachers()">
                            <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                :value="item.sysub_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="授课教师" prop="teuser_id">
                        <el-select v-model="lessonForm.teuser_id" placeholder="请选择" filterable clearable>
                            <el-option v-for="item in teachers" :key="item.teuser_id" :label="item.teuse_name"
                                :value="item.teuser_id" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('lessonForm')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="addLesson" :disabled="saveLoad" v-loading="saveLoad">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $timeTableInfo, $timeTableAdd, $timeTableEdit, $timeTableColDel, $timeTableRowlDel, $downloadTemplate, $importTimetable } from "@api/lesson"
import { $schoolTeachers } from "@api/common"
import { mapState } from "vuex";
import print from "print-js"; //引入
export default {
    name: 'lesson_setting',
    computed: {
        ...mapState('common', ['subjectList']),
        fileType() {
            let typeIcon = '';
            /* 常用文件类型 */
            const wordType = ['doc', "docx"]; // word文件类型
            const excelType = ['xls', 'xlsx', 'xlsm']; // excel文件类型
            const pptType = ['ppt', 'pps', 'pot', 'ppa']; // 演示文稿文件类型
            const audioType = ['mp3', 'wma', 'aac']; // 音频文件类型
            const videoType = ['mp4', 'avi', 'wma', 'rmvb', 'rm', 'flash', 'mid', '3gp']; // 视频文件类型
            const imgType = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'tif', 'bmp', 'webp']; // 图片文件类型
            return function (type) {
                switch (type) {
                    case 'txt':
                        typeIcon = '&#xe636;'
                        break;
                    case 'pdf':
                        typeIcon = '&#xe641;'
                        break;
                    default:
                        if (wordType.indexOf(type) != -1) {
                            typeIcon = '&#xe637;'
                        }
                        if (excelType.indexOf(type) != -1) {
                            typeIcon = '&#xe644;'
                        }
                        if (pptType.indexOf(type) != -1) {
                            typeIcon = '&#xe63c;'
                        }
                        if (audioType.indexOf(type) != -1) {
                            typeIcon = '&#xe634;'
                        }
                        if (videoType.indexOf(type) != -1) {
                            typeIcon = '&#xe638;'
                        }
                        if (imgType.indexOf(type) != -1) {
                            typeIcon = '&#xe639;'
                        }
                        break;
                };
                return typeIcon;
            }
        }
    },
    data() {
        return {
            tableData: [], // 课程表内容
            isEdit: false, // 编辑状态
            importDialog: false, // 导入弹窗
            lessonDialog: false, // 课程内容编辑弹窗
            teachers: [], // 教师数据
            lessonForm: {}, // 课程编辑内容
            rules: {
                sysub_id: [{ required: true, message: '请选择科目', trigger: 'change' }],
                teuser_id: [{ required: true, message: '请选择授课教师', trigger: 'change' }],
            },
            saveLoad: false,
            btnload: false,
            /* 文件列表 */
            fileList: []
        }
    },
    created() {
        this.getTimeTable();
    },
    methods: {
        /** 获取课程表数据 */
        async getTimeTable() {
            let { data } = await $timeTableInfo({
                sccla_sch_grade: this.$route.params.id.split(',')[0],
                sccla_id: this.$route.params.id.split(',')[1],
            });
            this.tableData = data;
            this.$forceUpdate();
        },
        /** 获取教师数据 */
        async getTeachers(id = null) {
            this.teachers = [];
            delete this.lessonForm.teuser_id;
            if (id) this.lessonForm.teuser_id = id;
            if (!this.lessonForm.sysub_id) return;
            let params = {
                sysub_id: this.lessonForm.sysub_id,
                sccla_sch_grade: this.$route.params.id.split(',')[0],
                sccla_id: this.$route.params.id.split(',')[1],
            }
            let { data } = await $schoolTeachers(params);
            this.teachers = [...data];
        },
        /** 课程填充 */
        addLesson() {
            this.$refs.lessonForm.validate(async (valid) => {
                if (valid) {
                    this.saveLoad = true;
                    if (this.lessonForm.sccla_sch_id) return this.editLesson();
                    let params = { ...this.lessonForm };
                    let res = await $timeTableAdd(params);
                    this.saveLoad = false;
                    if (res) {
                        this.hideDialog('lessonForm');
                        this.$message.success('课程内容保存成功')
                        this.getTimeTable();
                    }
                }
            });
        },
        /** 编辑课程内容 */
        async editLesson() {
            let params = { ...this.lessonForm };
            let res = await $timeTableEdit(params);
            this.saveLoad = false;
            if (res) {
                this.hideDialog('lessonForm');
                this.$message.success('课程内容编辑保存成功')
                this.getTimeTable();
            }
        },
        /**
         * 课程表内容清除
         * @param {number} id 课程id
         */
        delLessonCol(id) {
            this.$msgbox({
                title: '清除',
                message: '确认清除当前课程内容？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await $timeTableColDel(id);
                if (res) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '当前课程内容已清除！'
                    });
                    this.getTimeTable();
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /**
         * 清除整行课程内容
         * @param {number} id 课程时间段id
         */
        delLessonRow(id) {
            this.$msgbox({
                title: '清除',
                message: '确认清除此行课程内容？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await $timeTableRowlDel({sccla_sch_tim_id:id,sccla_id: this.$route.params.id.split(',')[1]});
                if (res) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '该行课程内容已清除！'
                    });
                    this.getTimeTable();
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 课程内容编辑弹窗【打开】 */
        async openLessonDialog(data, week) {
            if (week) {
                this.lessonForm = {
                    sccla_sch_tim_id: data,
                    sccla_sch_week: week,
                    sccla_sch_grade: this.$route.params.id.split(',')[0],
                    sccla_id: this.$route.params.id.split(',')[1],
                }
            } else {
                this.lessonForm = {
                    sccla_sch_id: data.sccla_sch_id,
                    sccla_sch_tim_id: data.sccla_sch_tim_id,
                    sccla_sch_week: data.sccla_sch_week,
                    sysub_id: data.sysub_id,
                }
            }
            this.getTeachers(data.teuser_id);
            this.lessonDialog = true;
        },
        /** 打印配置 */
        printRow() {
            printJS({
                printable: 'printData',// 标签元素id
                type: 'html', // 打印的类型
                header: '', // 用于HTML、图像或JSON打印的可选标题。它将被放置在页面的顶部。此属性将接受文本或原始HTML。
                targetStyles: ['*'], // 这样设置继承了页面要打印元素原有的css属性
                style: `@page {margin: 100px 10mm;}
                    th,
                    td {
                        width: 10%;
                        box-sizing: border-box;
                        padding: 0 10px;
                    }
                
                    th {
                        height: 50px;
                    }
                
                    td {
                        vertical-align: middle;
                        text-align: center;
                        padding: 4px;
                    
                        &:first-child {
                            font-size: 16px;
                        }
                    
                        .bold {
                            font-size: 16px;
                            font-weight: bolder;
                        }
                    
                        .light {
                            font-size: 14px;
                            font-weight: lighter;
                            word-wrap: break-word;
                            height:54px;
                            width:100%;
                            overflow: hidden;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin:0 auto;
                        }
                    }
                `, // 打印时去掉眉页眉尾
                scanStyles: false,
                //properties:是打印json时所需要的数据属性。
                //gridHeaderStyle和gridStyle都是打印json时可选的样式。
                //repeatTableHeader:在打印JSON数据时使用。设置为时false，数据表标题将仅在第一页显示。
                //scanStyles:设置为false时，库将不处理应用于正在打印的html的样式。使用css参数时很有用，此时自己设置的原来想要打印的样式就会失效，在打印预览时可以看到效果
                //ignoreElements：传入要打印的div中的子元素id，使其不打印。非常好用
            });
        },
        /** 导入弹窗【打开】 */
        openImport() {
            this.importDialog = true;
        },
        /** 下载课表模板 */
        async downloadTemplate() {
            let { data } = await $downloadTemplate();
            this.$fileDownload(data.full_file_url)
        },
        /** 导入课程表 */
        async uploadTimetable() {
            if (this.$isEmpty(this.fileList)) return this.$message.warning('请上传需要导入的文件');
            this.btnload = true;
            let params = {
                grade: this.$route.params.id.split(',')[0],
                sccla_id: this.$route.params.id.split(',')[1],
                inportfile: this.fileList[0].url
            }
            this.fileList = [];
            let res = await $importTimetable(params);
            this.btnload = false;
            if (res) {
                this.hideDialog();
                this.getTimeTable();
            }
        },
        /**
         * 文件上传成功
         * @param {object} data 响应结果
         * @param {string} name 上传原文件名
         */
        uploadSuccess({ data, msg }, { name }) {
            if (this.$isEmpty(data)) return this.$message.error(msg);
            let file = { name, type: data.file_type, url: data.address };
            this.fileList = [file];
        },
        /** 删除上传文件 */
        deleteUploadFile(index) {
            this.fileList.splice(index, 1)
        },
        /** 关闭弹窗 */
        hideDialog(ref) {
            this.lessonDialog = false;
            this.importDialog = false;
            if (ref) {
                this.resetForm(ref);
                this[ref] = {};
            }
        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        },
    },
}
</script>